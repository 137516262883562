import React from "react";
import Img from "gatsby-image";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Icon from "../../assets/svgs/arrow-white.svg";
import "./FeaturedLeadership.scss";

const FeaturedLeadership = ({
  backgroundColor,
  buttonLink,
  buttonText,
  buttonVarient,
  description,
  leaderName,
  linkedinUrl,
  title,
  image,
}) => {
  const FeatureImage = image?.localFile?.childImageSharp.fluid;
  return (
    <Section className="container">
      <section className="FeaturedLeadership" style={{ background: backgroundColor }}>
        <div className="container">
          <div className="row align-items-sm-start align-items-md-center">
            <div className="col-lg-5">
              <h4 className="Text--light">{title}</h4>
              <div className="FeaturedDescription ">
                <div className="Body--small" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              {!buttonLink ? null : (
                <Button
                  to={new URL(buttonLink).pathname + `/`}
                  variant={buttonVarient}
                  title={buttonText}
                >
                  {buttonText}
                </Button>
              )}
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="SectionLeaderInfo">
            <h5>{leaderName}</h5>
            <a className="Body--small" href={!linkedinUrl ? "#" : linkedinUrl}>
              View LinkedIn <img src={Icon} alt="Arrow" />
            </a>
          </div>
          <Img
            fluid={FeatureImage}
            alt={!image ? "Right Image" : title}
            className="SectionRightImage"
          />
        </div>
      </section>
    </Section>
  );
};

export default FeaturedLeadership;
